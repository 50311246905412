.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 235px;
    margin-bottom: 40px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    :global(.warTokensWrapper) {
        position: absolute !important;
            bottom: -25px;
        background: linear-gradient(to right, #222527 0%, #4e5746 40%, #4e5746 55%, #222529 85%);
    }
}

.background {
    position: absolute;
    width: inherit;
}

.name {
    padding-top: 5px;
    font-size: 1.8rem;

    &.crates {
        height: 55px;
        color: #fec923;
    }
}

.quantity {
    color: #b9fd9a;
    font-size: 2.5rem;
}

.fortuneEventWrapper {
    .quantity {
        font-size: 2rem;
    }
    .name {
        padding-top: 5px;
        font-size: 1.6rem;
    }
}

.gemsImage {
    top: 5px;
    width: 80px;

    &.crates {
        width: 120px;
        margin-top: -15px;
    }

    &.resources {
        width: 100px;
    }

    &.commander {
        width: 75px;
        margin-top: 20px;
    }
}

.pricesWrapper {
    position: absolute;
    bottom: 20px;
}

.oldPrice {
    position: relative;
    display: inline-block;
    font-size: 1.6rem;

    :global(.stroke) {
        position: absolute;
        top: 50%;
        height: 1px;
        color: #ffffff;
    }
}

.newPrice {
    font-size: 2.2rem;
}

.name,
.quantity,
.gemsImage,
.oldPrice,
.newPrice,
.fortuneImage {
    position: relative;
}

.outerWrapper {
    position: relative;
}

.info {
    position: absolute;
    top: 57px;
    left: 100px;
    width: 25px;
}

.infoClickArea {
    position: absolute;
    top: 50px;
    left: 85px;
    width: 50px;
    height: 50px;
    z-index: 99;
    cursor: help;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        margin-right: 20px;
        transition: transform 0.3s ease-out;
        &:hover {
            transform: translateY(-15px);
        }
    }
}