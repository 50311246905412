@import "../../sass/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: $headerHeight;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    padding: 0 25px;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.img {
    width: 100px;
}

.title {
    margin: 15px 0;
    font-size: 1.6rem;
    font-weight: bold;
}

.info {
    color: #ffffff;
    font-size: 1.4rem;
    text-align: center;
}

.backToShop {
    margin: 40px auto 0;
    //padding: 10px 35px;
    //color: #fbedb7;
    //font-size: 2rem;
    //font-weight: bold;
    //text-transform: uppercase;
    //background-color: #505747;
    //border-radius: 30px;
    //font-family: Oswald, Arial, sans-serif;
}

@media only screen and (min-width: 1024px) {
    .backgroundAddOn {
        width: 800px;
        min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));
        margin: 0 auto;
        background-color: $desktopPartialBackground;
    }

    .img {
        width: 150px;
    }

    .title {
        font-size: 2rem;
    }

    .info {
        font-size: 1.8rem;
    }
}

@media only screen and (min-width: 1366px) {
    .backgroundAddOn {
        width: 1200px;
    }

    .img {
        width: 250px;
    }

    .title {
        font-size: 2.5rem;
    }

    .info {
        font-size: 2rem;
    }
}