.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuItemWrapper {
    min-width: 75px;
    &.active {
        .menuItem {
            color: #ffd069 ;
            border: 1px solid #a5760f;

            .innerPart {
                padding: 3px 10px;
                background-color: #473b1d;
            }
        }
    }
}

.menuItem {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    color: #a3a4a5;
    font-size: 1.6rem;
    text-transform: uppercase;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: Oswald, Arial, sans-serif;
    transition: all 0.3s ease-out;

    .innerPart {
        padding: 3px 10px;
        background-color: transparent;
    }

    &:hover {
        color: #ffffff;
    }

    &.highlighted {
        color: #a5760f;
        border: 1px solid #a5760f;
        transition: all 0.3s ease-out;

        :hover {
            color: #ffd069;
        }

        .innerPart {
            padding: 3px 10px;
            background-color: #473b1d;
            transition: all 0.3s ease-out;
        }
    }

    &.special {
        color: #a77000;

        &:hover {
            color: #ffd069;
        }
    }

    &.shop {
        color: #a77000;
    }

    .indicator {
        position: absolute;
            top: 1px;
            right: 2px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
    }
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        margin-left: 10px;
    }

    .menuItem {
        font-size: 1.8rem;
    }
}

@media only screen and (min-width: 1920px) {
    .wrapper {
        margin-left: 20px;
    }

    .menuItem {
        font-size: 2.2rem;

        .innerPart {
            padding: 3px 15px;
        }

        &.highlighted {
            .innerPart {
                padding: 3px 15px;
            }
        }

        &.active,
        &:hover {
            .innerPart {
                padding: 3px 15px;
            }
        }
    }
}