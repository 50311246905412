@import "../../sass/variables";

.overlay {
    position: fixed;
    top: $headerHeight;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: calc(100vh - #{$headerHeight});
    background-color: rgba(0, 0, 0, 0.7);
}

.wrapper {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 280px;
    min-height: 200px;
    margin-top: -$headerHeight;
    padding: 20px;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    background: #4e5746;
    border-radius: 20px;
    box-shadow: 5px 5px 14px -2px #000;
    transform: translate(-50%, -50%);
}

.info {
    margin-bottom: 40px;
}