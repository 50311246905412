.wrapper {
    position: relative;
    width: 320px;
    height: 320px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;

    div {
        position: relative;
    }
}

.background {
    position: absolute;
        top: 0;
        left: 0;
    width: 100%;
}

.name {
    margin-bottom: 10px;
    font-size: 2.8rem;
}

.progressWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    font-size: 1.6rem;
}

.tokenImg {
    width: 20px;
}

.progressBarWrapper {
    position: relative;
    height: 32px;
    margin-bottom: 15px;
    padding-left: 10px;
    background-color: #000;
    border: 1px solid #4a593a;
}

.progressBar {
    position: absolute !important;
        top: 0;
        left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to right, #546640 0%, #6fa136 100%);
}

.percent {
    position: relative;
    color: #eefc85;
    font-style: italic;
}

.rewardAmountWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.reward {
    text-transform: capitalize;
}

.gemImg {
    width: 25px;
}

.buttonWrapper {
    position: absolute !important;
        left: 50%;
        bottom: 20px;
    transform: translateX(-50%); 
    cursor: pointer;
}

.claimed {
    padding: 5px;
    color: #aaa8ab;
    text-transform: uppercase;
}