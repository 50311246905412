@import "../../sass/variables";

.wrapper {
    color: #ffffff;
}

.offerImg {
    width: 140px;
    height: 134px;
    margin: 0 15px;
}

.titleWrapper {
    position: relative;
}

.title {
    margin: 10px 0;
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    font-family: Oswald, Arial, sans-serif;
}

.arrowGoBack {
    position: absolute;
    top: 50%;
    left: 5px;
    width: 25px;
    transform: translateY(-50%);
}

.offerInfo {
    display: flex;

    &.offerDoubleItemInfo {
        padding: 10px;
        gap: 10px;
        align-items: center;
    }

    .offerType {
        margin-bottom: 10px;
        color: #fbedb7;
        font-size: 2rem;
        font-family: Oswald, Arial, sans-serif;
    }

    .oldPrice {
        position: relative;
        display: inline-block;
        color: #adb2ba;
        font-size: 1.6rem;
        :global(.stroke) {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 1px;
            background-color: #adb2ba;
            transform: translateX(-50%);
        }
    }

    .newPrice {
        font-size: 2.2rem;
    }

    :global(.warTokensWrapper) {
        position: relative;
        background: none;
    }
}

.discountWrapper {
    width: 60%;
    margin-top: 15px;
    margin-left: 40%;
    font-size: 1.6rem;
}

.discountInfo {
    display: flex;
    justify-content: space-evenly;

    .names,
    .values {
        text-align: right;
        text-transform: capitalize;
    }
}

.total {
    margin: 20px 0;
    padding-right: 30px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: right;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.gemsImgWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 145px;
    margin: 0 15px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;

    .imgBackground {
        position: absolute;
        width: inherit;
    }

    .name {
        padding-top: 10px;
        font-size: 1.5rem;
    }

    .quantity {
        color: #b9fd9a;
        font-size: 1.6rem;
    }

    .gemsImage {
        top: 5px;
        width: 50px;

        &:global(.Resources),
        &:global(.Crates) {
            width: 80px;
        }
    }

    .name,
    .quantity,
    .gemsImage,
    .oldPrice,
    .newPrice {
        position: relative;
    }
}

.agree {
    font-size: 1.4rem;
    text-align: left;

    input {
        margin-right: 5px;
    }
}

.terms {
    color: #b9fd9a;
    text-decoration: underline;
}

.wrapperUserInteractions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 80%;
    margin: 0 auto;
}

.promoBannerMarketing {
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 10px;

    img {
        width: 100%;
        height: 100%;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 1px 1px 1px #000000;
        transform: translate(-50%, -50%);

    }
}

.wrapperEmailMarketing,
.wrapperEmailReceipt {
    input {
        margin-top: 3px;
        margin-bottom: 10px;
    }

    .enterEmail {
        width: 250px;
        margin-left: 17px;
        height: 20px;
        padding: 10px 0;
    }
}

.wrapperEmailReceipt {
    margin-top: 10px;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        min-height: calc(100vh - (#{$headerHeight}));
        margin: 0 auto;
        //margin-top: $headerHeightBig;
        padding-top: 10px;
        background-color: $desktopPartialBackground;
    }

    .offerInfo {
        align-items: center;
        min-height: 130px;

        &.offerDobuleItemInfo {
            flex-direction: row;
            padding: 20px;
            .buttonRemove {
                margin-left: auto;
            }
        }
        .buttonRemove {
            margin-left: 400px;
        }

        .offerType {
            font-size: 2.2rem;
        }

        .oldPrice {
            font-size: 1.8rem;
        }

        .newPrice {
            font-size: 2.5rem;
        }
    }

    .discountWrapper,
    .name,
    .quantity {
        font-size: 1.8rem;
    }

    .total {
        font-size: 2rem;
    }

    .discountWrapper {
        width: 30%;
        margin-top: 15px;
        margin-left: 70%;
        padding-right: 15px;
        font-size: 1.8rem;
    }

    .discountInfo {
        justify-content: space-between;
    }

    .buttonWrapper {
        justify-content: flex-end;
        padding-right: 15px;
    }

    .wrapperUserInteractions {
        align-self: flex-end;
        max-width: 50%;
        margin: 0;
    }
}

@media only screen and (min-width: 1367px) {
    .wrapper {
        max-width: 1200px;
    }

    .info {
        align-self: flex-start;
    }

    .offerImg {
        width: 200px;
        height: 187px;
        margin: 0 15px;
    }

    .offerInfo {
        .buttonRemove {
            margin-left: 700px;
        }
    }

    .gemsImgWrapper {
        width: 140px;
        height: 205px;

        .name {
            font-size: 1.8rem;
        }

        .quantity {
            font-size: 2.2rem;
        }

        .gemsImage {
            width: 65px;

            &:global(.Resources),
            &:global(.Crates) {
                width: 120px;
            }
        }
    }

    .promoBannerMarketing {
        span {
            font-size: 1.6rem;
        }
    }
}

@media only screen and (min-width: 1367px) {
    .offerInfo {
        min-height: 187px;
    }
}