@import "../../sass/variables";

.filterBarWrapper {
    position: fixed;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 10px 0;
    font-size: 2.5rem;
    color: #fff;
    background-color: #00426c;
    font-family: Oswald, Arial, sans-serif;

    .filterImage {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .text {
        text-transform: capitalize;
    }
}

.filtersWrapper {
    position: fixed;
    top: $headerHeight;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - #{$headerHeight + $filterPanelHeight});
    background-color: #35363a;
    overflow-y: auto;
    padding-bottom: $filterPanelHeight;

    .categoryWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 250px;
        margin: 20px 0;
        padding: 10px;
        color: #fff;
        font-size: 1.8rem;
        background-color: #4e5746;
        border-radius: 20px;
        font-family: Oswald, Arial, sans-serif;
        transition: all 0.4s ease-out;

        &:hover,
        &.active {
            color: #ffd069;
            background-color: #41483e;
        }
    }

    .tickImage {
        position: absolute;
        top: 50%;
        left: 15px;
        width: 20px;
        transform: translateY(-50%);
    }

    .categoryName {
        padding-left: 30px;
    }

    .categoryIcon {
        width: 30px;
    }
}

.badgeNew {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 2px 5px;
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px;
    background: red;
    box-shadow: -3px 1px 23px rgba(0, 0, 0, 0.7);
}

@media only screen and (max-height: 700px) {
    .filtersWrapper {
        padding-bottom: 150px;// 70px - filterBarWrapper height + iphone bullshits
    }
}