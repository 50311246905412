@import "../../sass/variables";

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    margin: 0 0 8px;
    padding-top: 20px;
    gap: 10px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &.darkBackground {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 33%;
        height: 300px;
        background-color: #1b1c1e;
    }

    .innerWrapper {
        position: relative;

        .background,
        .contentContainer {
            width: 100%;
            max-width: 315px;
        }
        .contentContainer {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            transform: translate(-50%, 0%);

            .name {
                padding-top: 5px;
                font-size: 1.8rem;
            }
            .quantity {
                font-size: 2.5rem;
                color: #b9fd9a;
            }
            .plusSign {
                align-self: center;
                width: 16px;
                height: 16px;
            }
            .itemImgae {
                width: 50%;
            }
            .contentTopContainer {
                display: flex;
                justify-content: center;
                width: 100%;

                .itemContainer {
                    width: 100%;
                }
            }

            .oldPrice {
                position: relative;
                display: inline-block;
                font-size: 1.6rem;

                :global(.stroke) {
                    position: absolute;
                    top: 50%;
                    color: #ffffff;
                }
            }

            .newPrice {
                font-size: 2.2rem;
            }
        }
    }
}

.warTokensWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 1.6rem;
    font-weight: bold;
    background: linear-gradient(to right, #222527 15%, #4e5746 40%, #4e5746 55%, #222529 85%);

    .warTokenImg {
        height: 30px;
        margin-left: 5px;
        width: auto !important;
    }
}

@media only screen and (max-width: 1025px) {
    .wrapper {
        &.darkBackground {
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        width: 33%;

        &.darkBackground {
            min-width: 324px;
            margin-bottom: calc(((100vw - 286px) - 66%) / 5);
        }
    }
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        &.darkBackground {
            margin: 0 3px 5px 0;
        }
    }
}

@media only screen and (min-width: 1920px) {
    .wrapper {
        width: 24%;
        margin-right: 5px;
    }
}
