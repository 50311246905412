.buttonMain {
    display: inline-block;
    padding: 10px 25px;
    color: #ffe8b0;
    font-size: 1.6rem;
    text-transform: uppercase;
    background-color: #3e7b2e;
    border-radius: 20px;
    cursor: pointer;
    font-family: Oswald, Arial, sans-serif;
    transition: all 0.3s ease-out;

    &.small {
        padding: 6px 20px;
        font-size: 1.4rem;
    }

    &.negative {
        background-color: #7e3232;
    }

    &.disabled {
        background-color: #aaa8ab;
        opacity: 0.6;

        &:hover {
            opacity: 0.6;
        }
    }

    &:hover {
        opacity: 0.8;
    }
}

@media only screen and (min-width: 1025px) {
    .buttonMain {
        padding: 12px 45px;
        font-size: 1.8rem;
        &.logout {
            padding: 12px 35px;
            font-size: 1.5rem;
        }
    }
}