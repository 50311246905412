@import "./src/sass/variables";

.footerWrapper {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    font-size: 1.1rem;
    background-color: #232528;
    font-family: Oswald, Arial, sans-serif;

    &.additionalMobileClass {
        margin-bottom: $filterHeight;
    }

    &.additionalLoginClass {
        background-color: transparent;
    }
}

.logo {
    height: 25px;
    margin-bottom: 5px;
}

.stillfront_logo {
    width: 18vw;
    height: auto;
    min-width: 100px;
    max-width: 250px;
}

.termsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 10px;
    color: #eec26b;

    a:hover {
        opacity: 0.7;
    }
}

.footerDivider {
    width: 1px;
    height: 10px;
    background: #88764f;
}

.faq {
    text-transform: uppercase;
}

.support {
    text-transform: capitalize;
}

@media only screen and (min-width: 768px) {
    .footerWrapper {
        font-size: 1.5rem;
    }

    .termsWrapper {
        max-width: 550px;
    }
}

@media only screen and (min-width: 1025px) {
    .footerWrapper {
        margin-bottom: 0;
        height: $footerHeight;

        .stillfront_logo {
            position: absolute;
            right: 0;
        }
    }
}

@media only screen and (min-width: 1920px) {
    .footerWrapper {
        font-size: 1.7rem;
    }

    .termsWrapper {
        max-width: 500px;
    }
}