@import "./src/sass/variables";

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: calc(100vh - #{$headerHeight});
    //margin-top: $headerHeight;
    background-image: url("../../assets/images/background_siege_login.jpg");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    width: 300px;
}

.infoText {
    padding: 10px 40px;
    color: #f9712e;
    font-size: 1.2rem;
    text-align: center;
    //background-color: #47322d;
}

.ctaWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    min-width: 320px;
}

.enterIdText {
    color: #ffffff;
    font-size: 1.6rem;
    text-align: center;
}

.inputWrapper {
    input {
        min-width: 250px;
        height: 40px;
        margin: 10px 0;
        padding: 5px;
        border: 1px solid #000;
        outline: 2px solid #88764f;
        border-radius: 3px;
    }
}

.errMessage {
    margin-bottom: 10px;
    padding: 0 15px;
    color: #ff6c3c;
    font-size: 1.4rem;
    text-align: center;
}

.troubleShoot {
    padding-bottom: 10px;
    color: #ffffff;
    font-size: 1.5rem;
    text-decoration: underline;
    text-align: center;
}

.linksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (min-width: 1025px) {
    .loginWrapper {
        min-height: calc(100vh - #{$headerHeightBig});
    }

    .infoText {
        font-size: 1.6rem;
    }

    .logo {
        width: 400px;
    }

    .inputWrapper {
        input {
            min-width: 350px;
        }
    }
}