@import "../../sass/variables";

.wrapper {
    position: relative;
    width: 100%;
    height: 435px;
    margin: 0 auto 8px;
    margin-bottom: 0;
    padding-top: 5px;
    color: #ffffff;
    background-color: #1b1c1e;
    cursor: pointer;

    &.noTimer {
        height: 415px;

        &>img {
            margin-top: 12px;
        }
    }
}
.endsIn {
    margin-top: 5px;
    font-size: 2rem;
    text-align: center;
}

.hoursLeft {
    color: #fbedb7;
    font-size: 2rem;
}

.offerImg {
    display: block;
    width: 320px !important;
    margin: 0 auto;
}
.priceWrapper {
    position: absolute;
    height: 60px;
    left: 50%;
    bottom: 75px;
    transform: translateX(-50%);

    &.noTimer {
        bottom: 78px;
    }

    img {
        width: 100px;
    }
}
.oldPrice {
    position: absolute;
    top: 10px;
    left: 50%;
    display: inline-block;
    font-size: 1.6rem;
    transform: translateX(-50%);

    :global(.stroke) {
        position: absolute;
        top: 50%;
    }
}
.newPrice{
    position: relative;
    top: 37px;
    margin-top: -5px;
    font-size: 2rem;
    filter: none;
    text-align: center;

    &.singlePrice {
        top: 25px;
    }
}

.pricesWrapper {
    position: absolute;
    left: 50%;
    bottom: 5px;
    width: 100%;
    height: inherit;
    transform: translateX(-50%);
}

.oldPrice,
.newPrice {
    font-weight: bold;
}

.wrapper.freeOffer {
    cursor: auto;
    .priceWrapper {
        cursor: pointer;
        .pricesWrapper {
            font-weight: bold;
            font-family: Oswald, Arial, sans-serif;
        }
    }
}

.warTokensWrapper {
    position: relative;
        bottom: -35px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 1.6rem;
    font-weight: bold;
    background: linear-gradient(to right, #222527 15%, #4e5746 40%, #4e5746 55%, #222529 85%);

    .warTokenImg {
        width: auto !important;
        height: 30px;
        margin-left: 5px;
    }
}

@media only screen and (min-width: 768px) {
    .wrapper {
        height: 450px;

        &.noTimer {
            height: 430px;

            &>img {
                margin-top: 30px;
            }
        }
    }
    .endsIn {
        margin-top: 15px;
    }

    .priceWrapper {
        bottom: 80px;

        &.noTimer {
            bottom: 75px;
        }

        img {
            width: auto;
        }
    }
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        width: 33%;
        min-width: 324px;
        height: 425px;
        margin-bottom: 5px;
        margin-bottom: calc(((100vw - 286px) - 66%) / 5);

        &.noTimer {
            height: 405px;

            &>img {
                margin-top: 30px;
            }
        }
    }

    .endsIn {
        margin-top: 15px;
        font-size: 1.6rem;
    }

    .offerImg {
        width: 95%;
        max-width: 325px;
    }

    .hoursLeft,
    .endsIn {
        font-size: 2rem;
        text-align: center;
    }

    .priceWrapper {
        bottom: 55px;

        &.noTimer {
            bottom: 52px;
        }

        img {
            width: auto;
        }
    }

    .oldPrice {
        font-size: 1.8rem;
    }

    .newPrice {
        font-size: 2.3rem;
    }
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        margin: 0;
        margin-right: 3px;
        margin-bottom: 5px;
        margin-bottom: calc(((100vw - 286px) - 99%) / 5);
    }
}

@media only screen and (min-width: 1920px) {
    .wrapper {
        width: 24%;
        margin-right: 5px;
    }
}