@import "../../sass/variables";

.wrapper {
    max-width: 350px;
    min-height: calc(100vh - (#{$headerHeight}));
    margin: $headerHeight auto 0;
}

.title {
    margin: 10px 0 20px 0;
    color: #ffffff;
    font-size: 2.5rem;
    text-align: center;
    font-family: Oswald, Arial, sans-serif;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        max-width: 800px;
        min-height: calc(100vh - (#{$headerHeightBig}));
        margin: $headerHeightBig auto 0;
        padding: 20px 40px;
        background-color: $desktopPartialBackground;
    }
}

@media only screen and (min-width: 1367px) {
    max-width: 1200px;
    padding: 20px 50px;
}