@import "../../sass/variables";

.wrapper {
    min-height: calc(100vh - #{$headerHeight});
}

.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.8);
}

.playerImg {
    width: 20px;
    margin-right: 5px;
}

.player {
    display: flex;
    align-items: center;
    justify-content: center;
}

.playerInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.playerName {
    color: #ffffff;
    font-size: 1.6rem;
    text-transform: capitalize;
    line-height: 16px;
}

.playerId {
    color: #aaa8ab;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 16px;
}

.specialOffersTitle,
.gambitOffersTitle {
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        //height: calc(100vh - (#{$headerHeightBig}));
        margin-top: 0;
    }
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        max-width: none;
    }
}