@import "../../sass/variables";

.headerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: $headerHeight;
    //padding: 0 5px;
    color: #ffffff;
    //background-color: $mainBackground;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: Oswald, Arial, sans-serif;
}

.name {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    font-weight: bold;
    line-height: $headerHeight;
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
}

.logo {
    margin-top: 10px;
    height: $headerHeight;
}

@media only screen and (min-width: 1025px) {
    .headerWrapper {
        align-items: center;
        justify-content: space-between;
        height: $headerHeightBig;
        padding: 0 8px;
        background-color: $mainDark;
    }

    .headerDesktopWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .name {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
    }

    .logo {
        //margin-top: -7px;
        height: $headerHeightBig;
    }
}