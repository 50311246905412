@import "../../sass/variables";

.wrapper {
    position: relative;
    top: 0;
    min-width: 200px;
    align-self: flex-start;
    height: $headerHeight;
    padding: 3px 15px;
    background-color: #000000;
    //border-radius: 20px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease-out;

    &.open {
        height: 150px;

        .horizontalDivider {
            transform: scaleX(1);
        }

        .arrow {
            transform: rotate(180deg);
        }

        .logout {
            transform: scaleY(1);
        }
    }
}

.playerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.playerImg {
    width: 25px;
}

.info {
    margin: 0 10px 0 10px;
    font-size: 1.6rem;
    text-transform: uppercase;
}

.id {
    color: #aaa8ab;
}

.arrow {
    width: 15px;
    transition: all 0.4s ease-out;
}

.horizontalDivider {
    width: 90%;
    height: 1px;
    margin: 10px auto;
    background-color: #aaa8ab;
    transform: scaleX(0);
    transition: all 0.4s ease-out;
}

.logout {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scaleY(0);
    transition: all 0.4s ease-out;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        height: $headerHeightBig;
    }

    .playerInfo {
        margin-top: 10px;
    }
}