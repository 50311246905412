@import "../../sass/variables";

.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    :global(.body) {
        margin-top: $headerHeight;
    }
}

.loading {
    width: 50px;
}