@import './sass/variables';

@font-face {
  font-family: Oswald;
  src: url("./assets/fonts/Oswald.ttf") format('truetype');
}

html {
  font-size: 10px;
  scroll-padding-top: 80px;
  font-family: Oswald, Arial, sans-serif;
}

body,
.body {
  position: relative;
  width: 100%;
  min-height: calc(100vh - #{$headerHeight});
  margin-top: $headerHeight;
  background-color: $mainBackground;
  -webkit-text-size-adjust: none;
  overflow-x: hidden;
  font-family: Oswald, Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

hr {
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  text-align: center;
}

a {
  text-decoration: none;
  color: #ffefbf;
}

.stroke {
  width: 90%;
  height: 2px;
  background-color: red;
}

.dividerHorizontal {
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: #393b3f;
}

// WAR TOKENS
.warTokensWrapper {
  position: relative;
      bottom: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 1.6rem;
  font-weight: bold;
  background: linear-gradient(to right, #222527 15%, #4e5746 40%, #4e5746 55%, #222529 85%);

  .warTokenImg {
      width: auto !important;
      height: 30px;
      margin-left: 5px;
  }
}

@media only screen and (max-width: 1919px) {
  .warTokensWrapper {
    bottom: 0;
  }
}

@media only screen and (min-width: 1025px) {
  body,
  .body {
    min-width: 1024px;
    min-height: calc(100vh - (#{$headerHeightBig}));
    margin-top: $headerHeightBig;
    background-color: $mainBackground;
    // CUSTOM SCROLL FIREFOX
    scrollbar-color: #41483e #808080;
    scrollbar-width: thin;
    // CUSTOM SCROLL CHROME
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      //height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px #808080;
      //border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #41483e;
      //border-radius: 10px;
      cursor: pointer;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4e5746;
    }
  }
}

// CSS TRANSITION
.fade-in-slide-down-appear {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-in-slide-down-appear-active {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-down-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-in-slide-down-enter-active {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-down-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-down-exit-active,
.fade-in-slide-down-exit-done {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 0;
  transform: translateY(-20px);
}

.fade-in-slide-up-appear {
  opacity: 0;
  transform: translateY(100px);
}

.fade-in-slide-up-appear-active {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-up-enter {
  opacity: 0;
  transform: translateY(100px);
}

.fade-in-slide-up-enter-active {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-slide-up-exit-active,
.fade-in-slide-up-exit-done {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 0;
  transform: translateY(100px);
}

.fade-in-slide-left-enter {
  opacity: 0;
  transform: translateX(50%);
}
.fade-in-slide-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
}
.fade-in-slide-left-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-in-slide-left-exit-active {
  opacity: 0;
  transform: translateX(50%);
  transition: opacity 400ms, transform 400ms;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}