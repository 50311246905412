@import "../../sass/variables";

.wrapper {
    min-height: calc(100vh - (#{$headerHeight}));
    margin-top: $headerHeight;
}

.title {
    color: #ffffff;
    font-size: 2.5rem;
    text-align: center;
    font-family: Oswald, Arial, sans-serif;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    padding: 0 30px;
    color: #ffffff;
    font-size: 1.5rem;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.step1Img,
.step2Img {
    width: 300px;
    margin-top: 10px;
    border: 2px solid #6b7262;
}

.step1Img {
    margin-bottom: 20px;
}

.backToLogin {
    margin: 40px auto 0;
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        width: 800px;
        margin: 0 auto;
        background-color: $desktopPartialBackground;
    }

    .backToLogin {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        padding-bottom: 40px;
    }

    .infoWrapper {
        font-size: 1.7rem;
    }

    .title {
        font-size: 3rem;
    }

    .step1Img,
    .step2Img {
        width: 500px;
    }
}