@import "../../sass/variables";

.overlay {
    position: fixed;
    top: $headerHeight;
    left: 0;
    z-index: 999;
    width: 100vw;
	// 57px is the height of the bottom button "all categories"
    height: calc(100vh - (#{$headerHeight} + 57px));
    background-color: rgba(0, 0, 0, 0.7);
}

.wrapper {
    position: absolute;
    z-index: 999999;
    top: calc(50% + #{$headerHeight});
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 320px;
    min-height: 200px;
    margin-top: -$headerHeight;
    padding: 10px;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    background: #40392f;
    border: 2px solid #bf913b;
    border-radius: 20px;
    box-shadow: 5px 5px 14px -2px #000;
    transform: translate(-50%, -50%);
}

.name {
    width: 100%;
    padding: 5px 0;
    font-size: 2.3rem;
    font-weight: bold;
    background-color: #695635;
    border: 2px solid #7c6948;
    border-radius: 10px;
    font-family: Arial, sans-serif;
}

.innerWrapper {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 5px;
    background-color: #d2dbe2;
    border-radius: 10px;
}

.crateContains {
    color: #000;
    font-size: 1.8rem;
    font-weight: bold;
}

.resourcesContent,
.perksContent {
    width: 100%;
    padding: 5px;
    background-color: #212622;
    border-radius: 10px;
}

.resourcesContent {
    margin: 10px 0;
}

.resourceInfo,
.perksInfo {
    margin-bottom: 5px;
    font-size: 1.6rem;
    font-weight: bold;
}

.resources,
.perks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.perks {
    //justify-content: center;
}

.resourceWrapper,
.perkWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 128px;
    margin: 5px;
    background-color: #504434;
    border-radius: 10px;
}

.resourceWrapper {
    padding: 5px 0;
}

.perkWrapper {
    padding: 10px;

    &:global(.common) {
        background-color: #304e28;
    }
    &:global(.uncommon) {
        background-color: #2f3d62;
    }
    &:global(.rare) {
        background-color: #4c2e62;
    }
    &:global(.heroic) {
        background-color: #7d282f;
    }
}

.resourceImg,
.perkImg {
    width: 40px;
    margin-right: 3px;
}

.perkImg {
    width: 35px;
}

.resourceName,
.perkType {
    font-size: 1.3rem;
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
}

.resourceQuantity {
    font-size: 1.7rem;
    text-align: left;
}

.perkQuantity {
    font-weight: bold;
    text-align: left;
}

.purchaseImg {
    width: 150px;
}

.buttonPurchase {
    position: relative;
    cursor: pointer;
}

.price {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    transform: translate(-50%, -50%);
}

.buttonClose {
    position: absolute;
    top: -20px;
    right: -15px;
    width: 60px;
    cursor: pointer;
}

@media only screen and (min-width: 1366px) {
    .wrapper {
        max-width: 515px;
    }
}

@media only screen and (min-width: 1024px) {
    .overlay {
        top: $headerHeightBig;
    }
}

@media only screen and (min-width: 700px) {
    .resourceWrapper,
    .perkWrapper {
        width: 146px;
    }

    .wrapper {
        top: calc(50% + #{$headerHeightBig});
    }
}