.buttonNew {
    display: inline-block;
    padding: 2px;
    color: #ffd069;
    font-size: 1.6rem;
    text-transform: uppercase;
    background-color: #000000;
    border: 1px solid #8c7549;
    backface-visibility: hidden;
    cursor: pointer;
    font-family: Oswald, Arial, sans-serif;
    transition: all 0.3s ease-out;

    &.small {
        font-size: 1.4rem;
    }

    &.negative {
        .innerPart {
            background-color: #7e3232;
        }
    }

    &:hover {
        opacity: 0.8;
    }

    &.fancy {
        border: 2px solid #88764f;

        .innerPart {
            padding: 8px 40px;
            color: #ffffff;
            background: linear-gradient(to right, #6fa137 0%, #53653f 100%);
        }
    }

    &.large {
        padding: 2px;
        border: 2px solid #88764f;

        .innerPart {
            padding: 8px 85px;
            color: #ffffff;
            background: linear-gradient(to right, #6fa137 0%, #53653f 100%);
        }
    }
    
    &.disabled {
        border: 1px solid #aaa8ab;
        .innerPart {
            color: #ffffff;
            background: linear-gradient(to right, #aaa8ab 0%, #3c3c3c 100%);
            opacity: 0.6;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .innerPart {
        padding: 3px 10px;
        background-color: #473b1d;
        transition: all 0.3s ease-out;
    }
}

@media only screen and (min-width: 1025px) {
    .buttonNew {
        padding: 4px;

        .innerPart {
            padding: 6px 20px;
        }
    }
}