@import "../../sass/variables";

.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.8);
}

.playerImg {
    width: 20px;
    margin-right: 5px;
}

.player {
    display: flex;
    align-items: center;
    justify-content: center;
}

.playerInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.playerName {
    color: #ffffff;
    font-size: 1.4rem;
    text-transform: capitalize;
    line-height: 16px;
}

.playerId {
    color: #aaa8ab;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 16px;
}