@import "../../sass/variables";

.offerType {
    margin-bottom: 10px;
    color: #fbedb7;
    font-size: 2rem;
    font-family: Oswald, Arial, sans-serif;
}

.oldPrice {
    position: relative;
    display: inline-block;
    color: #adb2ba;
    font-size: 1.6rem;

    :global(.stroke) {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 1px;
        background-color: #adb2ba;
        transform: translateX(-50%);
    }
}

.newPrice {
    font-size: 2.2rem;
}

.warTokensWrapper {
    :global(.warTokensWrapper) {
        position: relative;
        margin-bottom: 10px;
        background: none;
    }
}

@media only screen and (min-width: 1025px) {
    .buttonRemove {
        margin-left: auto;
    }

    .offerType {
        font-size: 2.2rem;
    }

    .oldPrice {
        font-size: 1.8rem;
    }

    .newPrice {
        font-size: 2.5rem;
    }
}

@media only screen and (min-width: 1367px) {
    .info {
        align-self: flex-start;
    }

    .buttonRemove {
        margin-left: auto;
    }
}
