@import "../../sass/variables";

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 35px;
    padding: 2px;
    background-color: rgba(0, 0, 0, 0.8);
    //border: 2px solid #ffffff;
    //border-radius: 5px;
    transition: all 0.4s ease-out;
    cursor: pointer;

    &.opened {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.flag {
    width: 28px;
    margin-right: 3px;
}

.language {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 30px;
    margin-right: 3px;
    padding-bottom: 2px;
}

.arrow {
    width: 12px;

    &.opened {
        transform: rotate(180deg);
        transition: transform 0.3s ease-out;
    }
}

.dropDownPart {
    position: absolute;
    z-index: 99999;
    width: 85px;
    background-color: #222529;
    //border: 2px solid #6b7262;
    //border-radius: 0 0 5px 5px;
    border-top: none;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-out;

    &.opened {
        transform: scaleY(1);
    }
}

.languageItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .flag {
        margin-left: 8px;
    }
}

@media only screen and (min-width: 1025px) {
    .wrapper {
        margin: 0 30px;
        //margin-top: 10px;
        background-color: $mainDark;
    }

    .dropDownPart {
        margin-left: 30px;
    }
}