.wrapper {
    font-size: 2rem;
}

.milestonesTitle {
    margin-top: 5px;
    margin-bottom: 8px;
    padding: 5px 0;
    color: #fff;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(to right, #222527 0%, #355590 25%, #355590 75%, #222529 100%);
}

.milestonesDescription {
    margin-bottom: 20px;
    padding: 0 10px;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
}

.imgPart {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    img {
        width: 30px;
    }
}

.milestoneArrow {
    display: block;
    margin: 15px auto;
    transform: rotate(90deg);
    cursor: pointer;
}

@media only screen and (min-width: 1024px) {
    .milestonesWrapper {
        display: flex;
        margin-top: 50px;
        padding: 0 25px 10px;
        overflow-x: auto;
        // CUSTOM SCROLL FIREFOX
        scrollbar-color: #41483e #808080;
        scrollbar-width: thin;
        // CUSTOM SCROLL CHROME
        /* width */
        &::-webkit-scrollbar {
        //   width: 8px;
        height: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px #808080;
        //border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #41483e;
        //border-radius: 10px;
        cursor: pointer;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #4e5746;
        }
    }


    .milestonesDescription,
    .milestonesTitle {
        width: 700px;
        margin: 0 auto;
    }

    .milestoneCardWrapper {
        display: flex;
        align-items: center;
    }

    .milestoneArrow {
        display: block;
        height: 60px;
        margin: 0 50px;
        transform: rotate(0deg);
    }
}