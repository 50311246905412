@import "../../../sass/variables";

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 45px;
    height: 40px;
    margin-left: 5px;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid #ffffff;
    border-radius: 5px;
}

.buttonPart {
    width: 70%;
    height: 2px;
    background-color: #ffffff;
    border-radius: 2px;
}

.menusList {
    position: absolute;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - (#{$headerHeight}));
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    background-color: #222529;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.4s ease-out;
    font-family: Oswald, Arial, sans-serif;

    &.opened {
        transform: scaleY(1);
    }

    :global(.dividerHorizontal) {
        width: 70%;
        margin: 0 auto;
    }
}

.menu {
    display: block;
    margin: 10px 0;

    &.shopMenu {
        color: #a77000;
    }

    &.active {
        color: #ffd069;    
    }
}

.activeMenu {
    color: #ffe8b0;
}

.nemuName {
    position: relative;
    display: inline-block;
}

.indicator {
    position: absolute;
    top: -3px;
    right: -8px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 45px;
    height: 40px;
    margin-left: 5px;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5px;

    .bar1, .bar2, .bar3 {
        width: 20px;
        height: 3px;
        background-color: #ffffff;
        margin: 4px 0;
        transition: all 0.4s ease-out;
    }

    &.open .bar1 {
        background-color: #ffffff;
        transform: translate(0, 11px) rotate(-45deg);
    }

    &.open .bar2 {
        background-color: #ffffff;
        opacity: 0;
    }

    &.open .bar3 {
        background-color: #ffffff;
        transform: translate(0, -9px) rotate(45deg);
    }

    .indicator {
        position: absolute;
            top: 3px;
            right: 8px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
    }
}