@import "../../sass/variables";

.resourcesTitle,
.cratesTitle,
.gemsTitle,
.specialOffersTitle,
.gambitOffersTitle,
.commandersTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 5px 20px;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    font-family: Oswald, Arial, sans-serif;
    background: linear-gradient(to right, #222527 0%, #4e5746 28%, #4e5746 70%, #222529 100%);
}

.gemsTitle {
    margin-top: -8px;
}

.specialOffersTitle,
.gambitOffersTitle {
    margin-top: 0;
    margin-bottom: 0;

    // &.everyDayOffer {
    //     margin-top: -8px;
    // }
}

.offersWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.resourcesPackages,
.cratesPackages,
.gemsPackages,
.commanderPackages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 auto;
}

.singleResourceTitle,
.commanderPackTitle {
    width: 75%;
    margin: 10px auto;
    padding: 5px 0;
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-family: Oswald, Arial, sans-serif;
    background: linear-gradient(to right, #222527 0%, #3b424a 45%, #3b424a 65%, #222529 100%);
}

.categoryIcon {
    width: 35px;
    margin-right: 7px;
}

// stuped apple fix
.specialOffersWrapper,
.gamitOffersWrapper,
.gemsWrapper,
.cratesWrapper,
.resourcesWrapper,
.commandersWrapper {
    margin-top: 50px;
}

.milestonesDescriptionWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    max-width: 750px;
    margin: 5px auto;
    padding: 5px;
    color: #fff;
    font-size: 1.2rem;
    background-color: #3e3f44;
    border-radius: 8px;
}

.milestonesDescription {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
}

.warTokenImg {
    height: 35px;
    margin-right: 5px;
}

.milestonesDescriptionCloseBtn {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 38px;
        height: 30px;
        margin-left: 5px;
        padding: 5px 0;
        cursor: pointer;

        .bar1, .bar2 {
            width: 20px;
            height: 3px;
            background-color: #ffffff;
            margin: 4px 0;
        }

        .bar1 {
            background-color: #ffffff;
            transform: translate(0, 5px) rotate(-45deg);
        }

        .bar2 {
            background-color: #ffffff;
            transform: translate(0, -6px) rotate(45deg);
        }
    }
}

@media only screen and (min-width: 1025px) {
    .allOffersWrapper {
        display: flex;
        justify-content: space-around;
    }

    .offersWrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .offersSideWrapper {
        margin-left: 290px;
    }

    .resourcesTitle,
    .cratesTitle,
    .gemsTitle,
    .specialOffersTitle,
    .gambitOffersTitle,
    .commandersTitle {
        position: relative;
        justify-content: flex-start;
        margin-bottom: 20px;
        font-size: 3.5rem;
        background: linear-gradient(to right, #4e5746 15%, transparent 100%);
        text-align: left;
        padding-left: 15px;
        width: 100%;
    }

    .gemsTitle {
        margin-top: -2px;
    }

    .specialOffersTitle,
    .gambitOffersTitle {
        margin-bottom: 0;
    }

    .milestonesDescriptionWrapper {
        margin: 5px 0;
        font-size: 1.5rem;
    }

    .specialOffersWrapper,
    .gamitOffersWrapper {
        max-width: none;
        margin: 0 auto;
    }

    .specialOffersWrapper,
    .gamitOffersWrapper,
    .gemsWrapper,
    .cratesWrapper,
    .resourcesWrapper,
    .commandersWrapper {
        margin-top: 0;
    }

    .resourcesPackages,
    .cratesPackages,
    .gemsPackages,
    .commanderPackages {
        width: 720px;
        justify-content: flex-start;
        max-width: none;
        margin: 0;
        margin-left: 20px;
    }

    .filterWrapper {
        position: fixed;
        top: $headerHeightBig;
        left: 0;
        height: calc(100vh - (#{$headerHeightBig}));
        padding: 0 18px;
        background-color: #2b2c2f;
    }

    .filterTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0 5px;
        font-size: 2.5rem;
        color: #fff;
        text-transform: capitalize;
        font-family: Oswald, Arial, sans-serif;
    }

    .filterIcon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .tickIcon {
        position: absolute;
        top: 50%;
        left: 15px;
        width: 20px;
        transform: translateY(-50%);
    }

    .categoryWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 250px;
        margin: 20px 0;
        color: #fff;
        font-size: 1.8rem;
        font-family: Oswald, Arial, sans-serif;
        cursor: pointer;
        background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #41483e 50%);
        background-size: 220%;
        transition: all 0.4s ease-out;

        &.active,
        &:hover {
            color: #ffd069;
            background-position: 100%;
        }

        .badgeNew {
            position: absolute;
            top: -10px;
            right: -10px;
            padding: 2px 5px;
            color: white;
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 3px;
            background: red;
            box-shadow: -3px 1px 23px rgba(0, 0, 0, 0.7);
        }
    }

    .categoryName {
        padding-left: 5px;
    }

    .singleResourceTitle,
    .commanderPackTitle {
        margin: 10px 0;
        padding-left: 15px;
        text-align: left;
        background: linear-gradient(to right, #3b424a 32%, transparent 100%);
    }

    .categoryFilterIcon {
        width: 35px;
    }

    .categoryIcon {
        width: 50px;
    }
}

@media only screen and (min-width: 1366px) {
    .milestonesDescriptionWrapper {
        max-width: 900px;
        font-size: 1.7rem;
    }

    .offersWrapper {
        justify-content: flex-start;
    }

    .offersSideWrapper {
        width: 100%;
        margin-left: 286px;
    }

    .resourcesWrapper,
    .cratesWrapper,
    .gemsWrapper,
    .commandersWrapper {
        max-width: none;
    }

    .resourcesPackages,
    .cratesPackages,
    .gemsPackages,
    .commanderPackages {
        max-width: 1200px;
        width: 1000px;
    }

    .singleResourceTitle,
    .commanderPackTitle {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1920px) {
    .allOffersWrapper {
        justify-content: initial;
    }


}