@import "../../../sass/variables";

$primaryColor: #abd180;
$hoverColor: #1b1c1e;


.installAppButton {
	height: 40px;
	margin-left: auto;
    margin-right: 20px;
    font-size: 1.8rem;
    border: 1px solid $primaryColor;
    text-transform: uppercase;
	color: $primaryColor;
    font-weight: bold;
	padding: 2px;
	
	.bookmarkBadge {
		height: 100%;
		align-items: center;
		display: flex;
		gap: 5px;
		transition: all 0.3s;
		padding: 5px 5px;

		svg{
			height: 100%;
			path {
				fill: $primaryColor;
			}
		}


		
		&:hover {
			color: $hoverColor;
			background-color: $primaryColor;
			cursor: pointer;

			path {
				fill: $hoverColor;
			}
		}
	}
}